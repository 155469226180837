import React, { useRef } from "react";
import { Carousel } from 'antd';
import { useSize } from 'ahooks';
import HostConfig from '$CONFIG/Host.config';
import LazyLoad from 'react-lazyload';
import CheckImageFormat from "../CheckImageFormat";
const CMSURL = HostConfig.Config.CMSURL;


const Banner = ( { bannerList, children} ) => {
	const bannerRef = useRef(null);
	const size = useSize(() => document.querySelector('body')) || {};
	const bodyWidth = size.width || 0;
	return (
		<>
			<section className="banner">
				<Carousel
					ref={bannerRef}
					autoplay={true}
					autoplaySpeed={4500}
					lazyLoad={true}
					dots={false}
				>
					{bannerList && bannerList.length &&
						bannerList.map((item, index) => {
							return(
								<LazyLoad key={index}>
									<div className="img_wrap">
										<CheckImageFormat
											avifImage={CMSURL + `${bodyWidth > 1023 ? item.cmsImageUrlWebAvif : item.cmsImageUrlMobileAvif}`}
											className={'imgItem'}
											defaultImage={CMSURL + `${bodyWidth > 1023 ?
													item.cmsImageUrlWebWebp || item.cmsImageUrlWeb :
													item.cmsImageUrlMobileWebp || item.cmsImageUrlMobile}`}
										/>
									</div>
								</LazyLoad>
							)
						})
					}
				</Carousel>
				{children}
			</section>
		</>
	)
	
}

export default Banner;
